<template>
  <div>
    <v-card>
      <v-card-text>

           <v-row>
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select v-model="report_type" :items="report_types" item-text="name" item-value="id" label="Report Type"></v-select>
          </v-col>
           <v-col cols="12" sm="4" md="4">
            <v-select v-model="request_status" :items="getLookup('RequestStatus')" item-value="id" label="Request Status"></v-select>
          </v-col>
           <v-col cols="12" sm="4" md="4">
            <v-select v-model="report_option" :items="report_options" item-text="name" item-value="id" label="Report Display Options"></v-select>
          </v-col>
        </v-row>

       




        <v-btn  color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>



      </v-card-text>
    </v-card>


      <v-row v-if="flag==1">
        <v-col  cols="12" sm="12" md="12">

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1133 style='width:849.85pt;border-collapse:collapse'>
     <tr>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
      <td width=23 style='width:17.25pt;padding:0in 0in 0in 0in;min-width: 6.09mm'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;min-width: 0.26mm'></td>
      <td width=1097 style='width:822.85pt;padding:0in 0in 0in 0in;min-width: 290.25mm'></td>
      <td width=12 style='width:9.0pt;padding:0in 0in 0in 0in;min-width: 3.17mm'></td>
     </tr>
     <tr style='height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=23 valign=top style='width:17.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 6.09mm'></td>
      <td width=1110 colspan=3 valign=top style='width:832.6pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 293.69mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:.25in'>
        <td width=1100 valign=top style='width:825.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 291.04mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;color:black'>CONSULTATION REPORT</span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:21.4pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:21.4pt'></td>
      <td width=24 colspan=2 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:21.4pt;min-width: 6.35mm'></td>
      <td width=1097 valign=top style='width:822.85pt;padding:0in 0in 0in 0in;
      height:21.4pt;min-width: 290.25mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:.25in'>
        <td width=1097 valign=top style='width:822.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 290.25mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;color:black'>Request Type: All Request Types ,
        Request Status: All Request Statuses , Report Type: Requests with Date
        Received from 2020-04-01 to 2020-11-05</span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
      height:21.4pt;min-width: 3.17mm'></td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 cols=4>
     <tr>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1133 style='width:849.85pt;border-collapse:collapse'>
       <tr style='height:3.3pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:3.3pt'></td>
        <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:3.3pt;
        min-width: 0.26mm'></td>
        <td width=766 style='width:574.55pt;padding:0in 0in 0in 0in;height:
        3.3pt;min-width: 202.67mm'></td>
        <td width=366 style='width:274.55pt;padding:0in 0in 0in 0in;height:
        3.3pt;min-width: 96.84mm'></td>
       </tr>
       <tr>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=767 colspan=2 valign=top style='width:575.35pt;padding:0in 0in 0in 0in;
        min-width: 202.94mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=767 style='width:575.35pt;border-collapse:collapse;min-width: 202.94mm'
         cols=6>
         <tr>
          <td width=194 style='width:145.5pt;padding:0in 0in 0in 0in;
          min-width: 202.94mm'></td>
          <td width=94 style='width:70.5pt;padding:0in 0in 0in 0in;min-width: 24.87mm'></td>
          <td width=112 style='width:84.0pt;padding:0in 0in 0in 0in;min-width: 29.63mm'></td>
          <td width=101 style='width:75.75pt;padding:0in 0in 0in 0in;
          min-width: 26.72mm'></td>
          <td width=133 style='width:99.75pt;padding:0in 0in 0in 0in;
          min-width: 35.19mm'></td>
          <td width=133 style='width:99.75pt;padding:0in 0in 0in 0in;
          min-width: 35.19mm'></td>
         </tr>
         <tr style='height:.25in'>
          <td width=767 colspan=6 valign=top style='width:575.35pt;border:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;color:black'>Consultation Summary</span></i></b></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=194 valign=top style='width:145.5pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Consultation Group</span></i></p>
          </td>
          <td width=94 valign=top style='width:70.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;color:black'>Initiated</span></p>
          </td>
          <td width=112 valign=top style='width:84.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;color:black'>Completed</span></p>
          </td>
          <td width=101 valign=top style='width:75.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;color:black'>Outstanding</span></p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;color:black'>Total</span></p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;color:black'>Average Turnaround
          Days</span></p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=194 valign=top style='width:145.5pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;color:black'>Other Government
          Departments</span></p>
          </td>
          <td width=94 valign=top style='width:70.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=112 valign=top style='width:84.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=101 valign=top style='width:75.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=194 valign=top style='width:145.5pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;color:black'>Other Governments</span></p>
          </td>
          <td width=94 valign=top style='width:70.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=112 valign=top style='width:84.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=101 valign=top style='width:75.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=194 valign=top style='width:145.5pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;color:black'>Third Parties</span></p>
          </td>
          <td width=94 valign=top style='width:70.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=112 valign=top style='width:84.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=101 valign=top style='width:75.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=501 colspan=4 valign=top style='width:375.8pt;border:solid silver 1.0pt;
          border-top:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;color:black'>Totals:</span></i></b></p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:8.0pt;color:black'>0</span></i></b></p>
          </td>
          <td width=133 valign=top style='width:99.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
         </tr>
        </table>
        </td>
        <td width=366 valign=top style='width:274.55pt;padding:0in 0in 0in 0in;
        min-width: 96.84mm'></td>
       </tr>
       <tr style='height:8.85pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:8.85pt'></td>
        <td width=1133 colspan=3 style='width:849.85pt;padding:0in 0in 0in 0in;
        height:8.85pt;min-width: 299.77mm'></td>
       </tr>
       <tr>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
        min-width: 0.26mm'></td>
        <td width=1132 colspan=2 valign=top style='width:849.1pt;padding:0in 0in 0in 0in;
        min-width: 299.51mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1131 style='width:848.35pt;border-collapse:collapse;min-width: 299.24mm'
         cols=14>
         <tr>
          <td width=104 style='width:78.0pt;padding:0in 0in 0in 0in;min-width: 299.24mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=45 style='width:33.75pt;padding:0in 0in 0in 0in;min-width: 11.91mm'></td>
          <td width=44 style='width:33.0pt;padding:0in 0in 0in 0in;min-width: 11.64mm'></td>
          <td width=43 style='width:32.25pt;padding:0in 0in 0in 0in;min-width: 11.38mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
          <td width=80 style='width:60.0pt;padding:0in 0in 0in 0in;min-width: 21.17mm'></td>
          <td width=58 style='width:43.5pt;padding:0in 0in 0in 0in;min-width: 15.35mm'></td>
          <td width=58 style='width:43.5pt;padding:0in 0in 0in 0in;min-width: 15.35mm'></td>
          <td width=135 style='width:101.25pt;padding:0in 0in 0in 0in;
          min-width: 35.72mm'></td>
          <td width=163 style='width:122.25pt;padding:0in 0in 0in 0in;
          min-width: 43.13mm'></td>
          <td width=75 style='width:56.25pt;padding:0in 0in 0in 0in;min-width: 19.84mm'></td>
          <td width=74 style='width:55.5pt;padding:0in 0in 0in 0in;min-width: 19.58mm'></td>
          <td width=85 style='width:63.75pt;padding:0in 0in 0in 0in;min-width: 22.49mm'></td>
         </tr>
         <tr style='height:.25in'>
          <td width=1131 colspan=14 valign=top style='width:848.35pt;
          border:solid darkgray 1.0pt;border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;color:black'>Consultation Details</span></i></b></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=104 valign=top style='width:78.0pt;border:solid darkgray 1.0pt;
          border-bottom:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=132 colspan=3 valign=top style='width:99.05pt;border:solid darkgray 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Extension</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=80 valign=top style='width:60.0pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'>&nbsp;</p>
          </td>
          <td width=116 colspan=2 valign=top style='width:87.0pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Pages</span></i></p>
          </td>
          <td width=135 valign=top style='width:101.25pt;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=163 valign=top style='width:122.25pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'>&nbsp;</p>
          </td>
          <td width=234 colspan=3 valign=top style='width:175.5pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Consult Days</span></i></p>
          </td>
         </tr>
         <tr style='height:15.1pt'>
          <td width=104 valign=top style='width:78.0pt;border-top:none;
          border-left:solid darkgray 1.0pt;border-bottom:solid silver 1.0pt;
          border-right:solid darkgray 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Request</span></i></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Date Complete Received</span></i></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Search</span></i></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>3rd Pty</span></i></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Conslt</span></i></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Due Date</span></i></p>
          </td>
          <td width=80 valign=top style='width:60.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><i><span
          style='font-size:8.0pt;color:black'>Date Closed</span></i></p>
          </td>
          <td width=58 valign=top style='width:43.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Review</span></i></p>
          </td>
          <td width=58 valign=top style='width:43.5pt;border:solid silver 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Release</span></i></p>
          </td>
          <td width=135 valign=top style='width:101.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Consultation</span></i></p>
          </td>
          <td width=163 valign=top style='width:122.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Consultee</span></i></p>
          </td>
          <td width=75 valign=top style='width:56.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Start Date</span></i></p>
          </td>
          <td width=74 valign=top style='width:55.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>End Date</span></i></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;color:black'>Turnaround Days</span></i></p>
          </td>
         </tr>
         <tr style='height:.25in'>
          <td width=194 colspan=2 valign=top style='width:145.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;color:black'>Total Requests: 0</span></i></b></p>
          </td>
          <td width=289 colspan=5 valign=top style='width:216.75pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;color:black'>Total Consultations: 0</span></i></b></p>
          </td>
          <td width=58 valign=top style='width:43.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=58 valign=top style='width:43.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=135 valign=top style='width:101.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=163 valign=top style='width:122.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=75 valign=top style='width:56.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=74 valign=top style='width:55.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
          <td width=85 valign=top style='width:63.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal>&nbsp;</p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1133 style='width:849.85pt;border-collapse:collapse' cols=5>
     <tr style='height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 0.53mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=747 style='width:560.3pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 197.64mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
     </tr>
     <tr style='height:2.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:2.25pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:2.25pt;min-width: 0.53mm'></td>
      <td width=192 rowspan=2 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:2.25pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;color:black'>2020-11-05
        10:46:37 AM</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=939 colspan=2 valign=top style='width:704.35pt;padding:0in 0in 0in 0in;
      height:2.25pt;min-width: 248.44mm'></td>
     </tr>
     <tr style='height:6.75pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:6.75pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:6.75pt;min-width: 0.53mm'></td>
      <td width=747 valign=top style='width:560.3pt;padding:0in 0in 0in 0in;
      height:6.75pt;min-width: 197.64mm'></td>
      <td width=192 rowspan=2 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:6.75pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Page 1 / 1</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='height:2.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.25pt'></td>
      <td width=941 colspan=3 rowspan=2 style='width:705.85pt;padding:0in 0in 0in 0in;
      height:2.25pt;min-width: 248.97mm'></td>
     </tr>
     <tr style='height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0>
       <tr style='height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;color:black'>Axcess-1</span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>
        
        </v-col>
        </v-row>

    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>

import DatePicker from "../components/DatePicker";
import { mapState,mapActions } from "vuex";
export default {

  components: {
 DatePicker,

  },
  data(){
    return {
      flag:0,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      report_types:[
        { id:'0',name:"Active Complaints as of 2020-11-03"},
        { id:'1',name:"Completed Complaints from 2015-01-01 to 2020-11-03"},
        { id:'2',name:"Recieved Complaints from 2015-01-01 to 2020-11-03"}


      ],
      report_type:"Active Complaints as of 2020-11-03",
    
      request_type:0,

        requests_status:[
        { id:0,name:"Active Court Procedings as of 2020-11-03"},
        { id:1 ,name:"Court Procedings Advised from 2020-04-01 to 2020-11-06"},
        { id:2 ,name:"Court Procedings Decisions from 2020-04-01 to 2020-11-06"},
        { id:3 ,name:"Court Procedings Filed From from 2020-04-01 to 2020-11-06"}

      ],
      request_status:0,

          report_options:[
        { id:0,name:"Active Court Procedings as of 2020-11-03"},
        { id:1 ,name:"Court Procedings Advised from 2020-04-01 to 2020-11-06"},
        { id:2 ,name:"Court Procedings Decisions from 2020-04-01 to 2020-11-06"},
        { id:3 ,name:"Court Procedings Filed From from 2020-04-01 to 2020-11-06"}

      ],
      report_option:0,
  
    };
  },



  methods:{
      ...mapActions(["retriveTypes"]),
    selected(){

    },


    getLookup(column){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },


    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    }
  },
   computed: {

   ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),

      ...mapState({
      request_types: "types",
    }),
   },
     async created(){
    await this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("configuration/retriveConfigChoices");
    
    },
    mounted () {
         this.retriveTypes();
        
           // console.log("heyy",this.configurations);
    },
  
};

</script>