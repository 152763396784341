<template>
  <v-menu
      ref="menu"
      :close-on-content-click="false"
      v-model="menu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-bind="attrs"
          :close-on-content-click="false"
          v-on="on"
          slot="activator"
          v-model="date"
          :label="label"
          solo
          outlined
          readonly
          @click="menu = true"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="menu = false">{{$t('clear')}}</v-btn>
      <v-btn color="primary" @click="send">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value','label'],
  data(){
    return {
      menu:false,
      date: ''
    }
  },
  mounted() {
    this.date = this.value;
  },
  watch:{
    value(){
      this.date = this.value;
    }
  },
  methods:{
    send(){
      this.$emit('input',this.date);
      this.menu = false;
    }
  }
}
</script>

<style scoped>

</style>